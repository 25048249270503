
export class Point {
  x: number;
  y: number;


  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  addX(aX: number): Point {
    return new Point(this.x + aX, this.y);
  }

  addY(aY: number): Point {
    return new Point(this.x, this.y + aY);
  }
}
