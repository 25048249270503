<div *ngIf="loading">
  Loading...
</div>
<div *ngIf="error">
  Error :(
</div>

<div id="pufferDataDiv">
  <canvas #pufferCanvas ></canvas>

  <div id="dateDiv" *ngIf="createdDate">
    {{ createdDate }}
  </div>

</div>
