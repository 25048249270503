import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MyDataLastGQL, MyDataLastQuery} from '../../generated/graphql';
import {Point} from './Point';
import {Size} from './Size';

@Component({
  selector: 'app-puffer',
  templateUrl: './puffer.component.html',
  styleUrls: ['./puffer.component.css']
})
export class PufferComponent implements OnInit {
  // values: Observable<DataallQuery['dataAll']>;
  queryResult: MyDataLastQuery['dataLast'];
  createdDate = '';
  loading = true;
  error: any;

  @ViewChild('pufferCanvas', {static: true})
  canvas: ElementRef<HTMLCanvasElement>;
  private ctx: CanvasRenderingContext2D;

  private colorTempMin = 30;
  private colorTempMax = 70;

  private pipeThickness = 5;
  private pumenRadius = 20;

  private canvasSize = new Size(500, 800);

  private pufferPos = new Point(200, 490);
  private pufferSize = new Size(100, 300);

  private hausSpitze = new Point(250, 0);
  private hausDachEndeLinks = new Point(10, 350);
  private hausDachEndeRechts = new Point(490, 350);
  private hausWandLinksOben = new Point(15, 343);
  private hausWandLinksUnten = new Point(15, 800);
  private hausWandRechtsOben = new Point(485, 343);
  private hausWandRechtsUnten = new Point(485, 800);

  private ofenPoint = new Point(50, 350);
  private ofenSize = new Size(100, 100);
  private schlotOben = new Point(50, 50);
  private ofenPumpeCenter = new Point(80, 760);
  private ofenPipeUnten1 = new Point(200, 760);
  private ofenPipeUnten2 = new Point(100, 760);
  private ofenPipeLinks1 = new Point(80, 740);
  private ofenPipeLinks2 = new Point(80, 450);
  private ofenRueckLinks1 = new Point(120, 450);
  private ofenRueckLinks2 = new Point(120, 500);
  private ofenRueckUnten2 = new Point(200, 500);

  private solarPanelWest1 = new Point(200, 60);
  private solarPanelWest2 = new Point(150, 130);
  private solarPanelEast1 = new Point(300, 60);
  private solarPanelEast2 = new Point(350, 130);

  private solarDownPipe1 = new Point(220, 490);
  private solarDownPipe2 = new Point(220, 125);
  private solarDownPipe3 = new Point(155, 125);
  private solarDownPipe4 = new Point(345, 125);

  private solarUpPipe1 = new Point(280, 490);
  private solarUpPipe2 = new Point(280, 70);
  private solarUpPipe3 = new Point(190, 70);
  private solarUpPipe4 = new Point(310, 70);

  private solarUpPump = new Point(280, 430);


  private heizungPipe1 = new Point(300, 640);
  private heizungPipe2 = new Point(350, 640);
  private heizungPipe3 = new Point(350, 400);
  private heizungPipe4 = new Point(430, 400);
  private heizungPipe5 = new Point(430, 760);
  private heizungPipe6 = new Point(300, 760);

  constructor(private dataLastGQL: MyDataLastGQL) {
  }

  ngOnInit(): void {
    // this.values = this.dataLastGQL.watch().valueChanges.pipe(map(result => result.data.dataAll));
    this.dataLastGQL.watch().valueChanges.subscribe((result) => {
        this.error = result.error;
        this.loading = result.loading;
        this.queryResult = result.data.dataLast;
        const test = +this.queryResult.createdDate;
        this.createdDate = new Date(test).toLocaleString('de-DE');
        this.draw();
      }
    );


    // this.values.subscribe({
    //   next(dataAll): void {
    //     dataAll.forEach( (it)  => {
    //       console.log(it.pufferOben);
    //     });
    //   },
    //   complete(): void{
    //     console.log('finished');
    //   }color depending on value
    // });
  }


  draw(): void {
    if (this.canvas == null || this.queryResult == null) {
      return;
    }
    this.ctx = this.canvas.nativeElement.getContext('2d');

    this.ctx.canvas.height = this.canvasSize.height;
    this.ctx.canvas.width = this.canvasSize.width;


    this.drawHouse();
    this.drawPuffer();
    this.drawOfen();
    this.drawSolar();
    this.drawHeizung();


  }

  private drawPuffer(): void {
    const gradient = this.ctx.createLinearGradient(this.pufferPos.x + this.pufferSize.width / 2, this.pufferPos.y,
      this.pufferPos.x + this.pufferSize.width / 2, this.pufferPos.y + this.pufferSize.height);
    gradient.addColorStop(0, this.getColor(this.queryResult.pufferOben));
    gradient.addColorStop(0.33, this.getColor(this.queryResult.pufferMitteOben));
    gradient.addColorStop(0.66, this.getColor(this.queryResult.pufferMitteUnten));
    gradient.addColorStop(1, this.getColor(this.queryResult.pufferUnten));
    this.drawPufferBlock(this.queryResult.pufferOben, 0, gradient);
    this.drawPufferBlock(this.queryResult.pufferMitteOben, 1, gradient);
    this.drawPufferBlock(this.queryResult.pufferMitteUnten, 2, gradient);
    this.drawPufferBlock(this.queryResult.pufferUnten, 3, gradient);
  }

  private drawPufferBlock(temp: number, yPos: number, gradient: CanvasGradient): void {
    const blockHeight = this.pufferSize.height / 4;
    const yStart = yPos * blockHeight + this.pufferPos.y;
    this.drawRect(new Point(this.pufferPos.x, yStart), new Size(this.pufferSize.width, blockHeight), gradient);
    this.drawTemp(temp, new Point(this.pufferPos.x + (this.pufferSize.width / 4), yStart + (blockHeight / 2.2)));

  }

  private drawTemp(temp: number, pos: Point, color: string = 'white'): void {
    this.drawText(temp.toFixed(1).toString() + '°C', pos, color);
  }

  private drawText(text: string, pos: Point, color: string = 'white'): void {
    this.ctx.fillStyle = color;
    this.ctx.textBaseline = 'top';
    this.ctx.font = '20px Arial';
    this.ctx.fillText(text, pos.x, pos.y);
  }

  private getColor(temp: number): string {
    return '#' + this.getSingleColor(temp, false) + '00' + this.getSingleColor(temp, true);
  }

  private getSingleColor(currentTemp: number, reverse: boolean): string {
    if (currentTemp < this.colorTempMin) {
      currentTemp = this.colorTempMin;
    }
    if (currentTemp > this.colorTempMax) {
      currentTemp = this.colorTempMax;
    }
    const range = this.colorTempMax - this.colorTempMin;
    const relativeTemp = currentTemp - this.colorTempMin;
    let percent = relativeTemp / range;
    if (reverse) {
      percent = 1 - percent;
    }
    const color = percent * 255;
    const value = Math.round(color).toString(16);
    if (value.length === 1) {
      return '0' + value;
    } else {
      return value;
    }
  }


  private drawHouse(): void {
    this.drawLine(this.hausSpitze, this.hausDachEndeLinks);
    this.drawLine(this.hausSpitze, this.hausDachEndeRechts);

    this.drawLine(this.hausWandLinksOben, this.hausWandLinksUnten);
    this.drawLine(this.hausWandRechtsOben, this.hausWandRechtsUnten);
  }

  private drawLine(startPoint: Point, endPoint: Point, color: string = 'black', lineWidth: number = 1): void {
    this.ctx.beginPath();
    this.ctx.strokeStyle = color;
    this.ctx.lineWidth = lineWidth;
    this.ctx.moveTo(startPoint.x, startPoint.y);
    this.ctx.lineTo(endPoint.x, endPoint.y);
    this.ctx.stroke();
  }

  private drawRect(startPoint: Point, size: Size, color: string | CanvasGradient | CanvasPattern): void {
    this.ctx.fillStyle = color;
    this.ctx.fillRect(startPoint.x, startPoint.y, size.width, size.height);
  }

  private drawPumpe(center: Point, waterColor: string): void {
    this.ctx.beginPath();
    this.ctx.arc(center.x, center.y, this.pumenRadius, 0, 2 * Math.PI);
    this.ctx.fillStyle = waterColor;
    this.ctx.fill();
  }

  private drawOfen(): void {
    let waterColorVorlauf: string;
    if (this.queryResult.ofenPumpe === 0) {
      waterColorVorlauf = this.getColor(30);
    } else {
      waterColorVorlauf = this.getColor(Math.max(Math.min(60, this.queryResult.ofenRuecklauf), this.queryResult.pufferUnten));
    }
    const waterColorRuecklauf = this.getColor(this.queryResult.ofenRuecklauf);

    this.drawRect(this.ofenPoint, this.ofenSize, waterColorRuecklauf);
    this.drawLine(this.ofenPoint, this.schlotOben, 'grey', 5);
    this.drawTemp(this.queryResult.ofenRuecklauf, this.ofenPoint.addX(this.ofenSize.width / 4.4).addY(this.ofenSize.height / 2.5));

    this.drawPumpe(this.ofenPumpeCenter, waterColorVorlauf);
    this.drawLine(this.ofenPipeUnten1, this.ofenPipeUnten2, waterColorVorlauf, this.pipeThickness);
    this.drawLine(this.ofenPipeLinks1, this.ofenPipeLinks2, waterColorVorlauf, this.pipeThickness);
    this.drawLine(this.ofenRueckLinks1, this.ofenRueckLinks2, waterColorRuecklauf, this.pipeThickness);
    this.drawLine(this.ofenRueckLinks2, this.ofenRueckUnten2, waterColorRuecklauf, this.pipeThickness);

    this.drawText(this.queryResult.ofenPumpe.toString(), this.ofenPumpeCenter.addX(-10).addY(-8));

  }

  private drawSolar(): void {
    const colorEast = this.getColor(this.queryResult.solarOst);
    const colorWest = this.getColor(this.queryResult.solarWest);
    let waterColor;
    if (this.queryResult.solarPumpeStatus) {
      waterColor = this.getColor(Math.max(this.queryResult.solarOst, this.queryResult.solarWest));
    } else {
      waterColor = this.getColor(20);
    }

    this.drawLine(this.solarPanelWest1, this.solarPanelWest2, colorWest, 8);
    this.drawLine(this.solarPanelEast1, this.solarPanelEast2, colorEast, 8);

    this.drawLine(this.solarDownPipe1, this.solarDownPipe2, waterColor, this.pipeThickness);
    this.drawLine(this.solarDownPipe2, this.solarDownPipe3, waterColor, this.pipeThickness);
    this.drawLine(this.solarDownPipe2, this.solarDownPipe4, waterColor, this.pipeThickness);


    this.drawLine(this.solarUpPipe1, this.solarUpPipe2, waterColor, this.pipeThickness);
    this.drawLine(this.solarUpPipe2, this.solarUpPipe3, waterColor, this.pipeThickness);
    this.drawLine(this.solarUpPipe2, this.solarUpPipe4, waterColor, this.pipeThickness);

    this.drawPumpe(this.solarUpPump, waterColor);
    this.drawEinAus(this.queryResult.solarPumpeStatus, this.solarUpPump);

    this.drawTemp(this.queryResult.solarOst, this.solarPanelEast1.addX(35).addY(10), 'black');
    this.drawTemp(this.queryResult.solarWest, this.solarPanelWest1.addX(-80).addY(10), 'black');


  }

  private drawHeizung(): void {
    const waterColorVor = this.getColor(this.queryResult.heizkoerperVorlauf);
    const waterColorRueck = this.getColor(this.queryResult.pufferUnten);

    this.drawLine(this.heizungPipe1, this.heizungPipe2, waterColorVor, this.pipeThickness);
    this.drawLine(this.heizungPipe2, this.heizungPipe3, waterColorVor, this.pipeThickness);
    this.drawLine(this.heizungPipe3, this.heizungPipe4, waterColorVor, this.pipeThickness);
    this.drawLine(this.heizungPipe4, this.heizungPipe5, waterColorRueck, this.pipeThickness);
    this.drawLine(this.heizungPipe5, this.heizungPipe6, waterColorRueck, this.pipeThickness);
    this.drawPumpe(this.heizungPipe5, waterColorRueck);

    this.drawTemp(this.queryResult.heizkoerperVorlauf, this.heizungPipe3.addY(5).addX(8), 'black');
    this.drawTemp(this.queryResult.raumTemp, this.heizungPipe3.addY(-100), 'black');


    this.drawEinAus(this.queryResult.heizkoerperPumpeStatus, this.heizungPipe5);

  }

  private drawEinAus(status: boolean, pos: Point, color: string = 'white'): void {
    pos = pos.addY(-8).addX(-16);
    if (status) {
      this.drawText('Ein', pos, color);
    } else {
      this.drawText('Aus', pos, color);
    }
  }
}
