import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};



export type Data = {
  __typename?: 'Data';
  createdDate?: Maybe<Scalars['ID']>;
  solarOst?: Maybe<Scalars['Float']>;
  solarWest?: Maybe<Scalars['Float']>;
  ofenRuecklauf?: Maybe<Scalars['Float']>;
  warmwasser?: Maybe<Scalars['Float']>;
  pufferOben?: Maybe<Scalars['Float']>;
  pufferMitteOben?: Maybe<Scalars['Float']>;
  pufferMitteUnten?: Maybe<Scalars['Float']>;
  pufferUnten?: Maybe<Scalars['Float']>;
  raumTemp?: Maybe<Scalars['Float']>;
  heizkoerperVorlauf?: Maybe<Scalars['Float']>;
  heizkoerperBadVorlauf?: Maybe<Scalars['Float']>;
  analog12?: Maybe<Scalars['Float']>;
  analog13?: Maybe<Scalars['Float']>;
  solarPumpe?: Maybe<Scalars['Int']>;
  ofenPumpe?: Maybe<Scalars['Int']>;
  waermetauscherPumpe?: Maybe<Scalars['Int']>;
  solarPumpeStatus?: Maybe<Scalars['Boolean']>;
  ventilSolarOst?: Maybe<Scalars['Boolean']>;
  ventilSolarWest?: Maybe<Scalars['Boolean']>;
  solarUv?: Maybe<Scalars['Boolean']>;
  kesselPumpeStatus?: Maybe<Scalars['Boolean']>;
  waermetauscherPumpeStatus?: Maybe<Scalars['Boolean']>;
  mischerHausAuf?: Maybe<Scalars['Boolean']>;
  mischerHausZu?: Maybe<Scalars['Boolean']>;
  heizkoerperBadPumpeStatus?: Maybe<Scalars['Boolean']>;
  heizkoerperPumpeStatus?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addData: Data;
};


export type MutationAddDataArgs = {
  solarOst?: Maybe<Scalars['Float']>;
  solarWest?: Maybe<Scalars['Float']>;
  ofenRuecklauf?: Maybe<Scalars['Float']>;
  warmwasser?: Maybe<Scalars['Float']>;
  pufferOben?: Maybe<Scalars['Float']>;
  pufferMitteOben?: Maybe<Scalars['Float']>;
  pufferMitteUnten?: Maybe<Scalars['Float']>;
  pufferUnten?: Maybe<Scalars['Float']>;
  raumTemp?: Maybe<Scalars['Float']>;
  heizkoerperVorlauf?: Maybe<Scalars['Float']>;
  heizkoerperBadVorlauf?: Maybe<Scalars['Float']>;
  analog12?: Maybe<Scalars['Float']>;
  analog13?: Maybe<Scalars['Float']>;
  solarPumpe?: Maybe<Scalars['Int']>;
  ofenPumpe?: Maybe<Scalars['Int']>;
  waermetauscherPumpe?: Maybe<Scalars['Int']>;
  solarPumpeStatus?: Maybe<Scalars['Boolean']>;
  ventilSolarOst?: Maybe<Scalars['Boolean']>;
  ventilSolarWest?: Maybe<Scalars['Boolean']>;
  solarUv?: Maybe<Scalars['Boolean']>;
  kesselPumpeStatus?: Maybe<Scalars['Boolean']>;
  waermetauscherPumpeStatus?: Maybe<Scalars['Boolean']>;
  mischerHausAuf?: Maybe<Scalars['Boolean']>;
  mischerHausZu?: Maybe<Scalars['Boolean']>;
  heizkoerperBadPumpeStatus?: Maybe<Scalars['Boolean']>;
  heizkoerperPumpeStatus?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  dataLast?: Maybe<Data>;
};

export type MyDataLastQueryVariables = Exact<{ [key: string]: never; }>;


export type MyDataLastQuery = (
  { __typename?: 'Query' }
  & { dataLast?: Maybe<(
    { __typename?: 'Data' }
    & Pick<Data, 'pufferOben' | 'pufferMitteOben' | 'pufferMitteUnten' | 'pufferUnten' | 'createdDate' | 'ofenRuecklauf' | 'ofenPumpe' | 'solarOst' | 'solarWest' | 'solarPumpeStatus' | 'heizkoerperVorlauf' | 'heizkoerperPumpeStatus' | 'raumTemp'>
  )> }
);

export const MyDataLastDocument = gql`
    query myDataLast {
  dataLast {
    pufferOben
    pufferMitteOben
    pufferMitteUnten
    pufferUnten
    createdDate
    ofenRuecklauf
    ofenPumpe
    solarOst
    solarWest
    solarPumpeStatus
    heizkoerperVorlauf
    heizkoerperPumpeStatus
    raumTemp
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyDataLastGQL extends Apollo.Query<MyDataLastQuery, MyDataLastQueryVariables> {
    document = MyDataLastDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

export const MyDataLast = gql`
    query myDataLast {
  dataLast {
    pufferOben
    pufferMitteOben
    pufferMitteUnten
    pufferUnten
    createdDate
    ofenRuecklauf
    ofenPumpe
    solarOst
    solarWest
    solarPumpeStatus
    heizkoerperVorlauf
    heizkoerperPumpeStatus
    raumTemp
  }
}
    `;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
